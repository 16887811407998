import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import(/* webpackChunkName: "HomeView" */ '../views/HomeView.vue')
  },
  {
    path: '/notes',
    name: 'NotesView',
    component: () => import(/* webpackChunkName: "NotesView" */ '../views/NotesView.vue')
  },
  {
    path: '/settings',
    name: 'SettingsView',
    component: () => import(/* webpackChunkName: "SettingsView" */ '../views/SettingsView.vue')
  },
  {
    path: '/help',
    name: 'HelpView',
    component: () => import(/* webpackChunkName: "HelpView" */ '../views/HelpView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
