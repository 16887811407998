/**************
 * MUTATIONS *
 *************/

// update the party details
const updateParty = (state, party) => {
    state.party = party;
}

// update the loot list
const updateLoot = (state, loot) => {
    state.loot = loot;
}

// update the macro list
const updateMacros = (state, macros) => {
    state.macros = macros;
}

// update the log list
const updateLogs = (state, logs) => {
    state.logs = logs;
}

// update the list of unsub functions for firestore
const addUnsub = (state, unsub) => {
    if (!state.unsub.includes(unsub)) {
        state.unsub.push(unsub);
    }
}

// log the party out by resetting the state to default
const logOut = (state) => {
    state.party = null;
    state.loot = [];
    state.macros = [];
    state.logs = [];
    state.unsub = [];
}

/********************
 * EXPORT MUTATIONS *
 ********************/

// export all mutations
export default {
    updateParty,
    updateLoot,
    updateMacros,
    updateLogs,
    addUnsub,
    logOut
};