import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { db } from './db/firebase.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import './plugins/vee-validate'

// config
Vue.config.productionTip = false

// database
Vue.prototype.$db = db; 

// validation providers + observers
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// initialize app
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
