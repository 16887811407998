import { collection, query, doc, addDoc, setDoc, updateDoc, deleteDoc, getDoc, getDocs, onSnapshot, Timestamp } from 'firebase/firestore'
import { getAuth, deleteUser, updateEmail, updatePassword, signInWithEmailAndPassword, createUserWithEmailAndPassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { db } from '../db/firebase.js'

// date settings
const dateSettings = { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
}

/*************
 * LISTENERS *
 *************/

// set up party listener for firestore
const bindParty = ({ commit }) => {
    // fetch party details
    const auth = getAuth();
    const partyRef = doc(db, "parties", auth.currentUser.uid);
    
    const unsubParty = onSnapshot(partyRef, (partyDoc) => {
        const party = {
            'id' : auth.currentUser.uid,
            'partyName' : partyDoc.get('party_name'),
            'partyMembers' : partyDoc.get('party_members'),
            'coins' : partyDoc.get('coins'),
            'notes': partyDoc.get('notes')
        };

        // commit party details to state
        commit('updateParty', party)
    });

    // commit unsub function to state
    commit('addUnsub', unsubParty)
}

// set up loot listener for firestore
const bindLoot = ({ commit }) => {
    // fetch loot details
    const auth = getAuth();
    const partyRef = doc(db, "parties", auth.currentUser.uid);
    const q = query(collection(partyRef, "items"));
    
    const unsubLoot = onSnapshot(q, (lootDocs) => {
        const loot = [];
        lootDocs.forEach((item) => {
            loot.push({
                'id': item.id,
                'name': item.get('name'),
                'description': item.get('description'),
                'valueAmount': item.get('value_amount'), 
                'valueCurrency' : item.get('value_currency'),
                'weight': item.get('weight'),
                'tags': item.get('tags'),
                'individuals': item.get('individuals'),
                'createdAt' : item.get('created_at').toDate().toLocaleString('en-US', dateSettings),
                'lastModifiedAt' : item.get('last_modified_at').toDate().toLocaleString('en-US', dateSettings)
            });
        });

        // commit loot details to state
        commit('updateLoot', loot)
    });

    // commit unsub function to state
    commit('addUnsub', unsubLoot);
}

// set up macros listener for firestore
const bindMacros = ({ commit }) => {
    // fetch macros details
    const auth = getAuth();
    const partyRef = doc(db, "parties", auth.currentUser.uid);
    const q = query(collection(partyRef, "macros"));
    
    const unsubMacros = onSnapshot(q, (macrosDocs) => {
        const macros = [];
        macrosDocs.forEach((macro) => {
            macros.push({
                'id' : macro.id,
                'name' : macro.get('name'),
                'description' : macro.get('description'),
                'transactionType' : macro.get('transaction_type'),
                'valueAmount': macro.get('value_amount'), 
                'valueCurrency' : macro.get('value_currency'),
            });
        });

        // commit macros details to state
        commit('updateMacros', macros)
    });

    // commit unsub function to state
    commit('addUnsub', unsubMacros);
}

// set up logs listener for firestore
const bindLogs = ({ commit }) => {
    // fetch logs details
    const auth = getAuth();
    const partyRef = doc(db, "parties", auth.currentUser.uid);
    const q = query(collection(partyRef, "logs"));
    
    const unsubLogs = onSnapshot(q, (logsDocs) => {
        const logs = [];
        logsDocs.forEach((log) => {
            logs.push({
                'id' : log.id,
                'time' : log.get('time').toDate().toLocaleString('en-US', dateSettings),
                'reason' : log.get('reason'),
                'transactionType' : log.get('transaction_type'),
                'valueAmount' : log.get('value_amount'),
                'valueCurrency' : log.get('value_currency')
            });
        });

        // commit logs details to state
        commit('updateLogs', logs)
    });

    // commit unsub function to state
    commit('addUnsub', unsubLogs);
}

// set up all listeners
const loadListeners = async ({ dispatch }) => {
    // check auth before loading listeners
    // log out if the user is not logged in
    getAuth().onAuthStateChanged(async (user) => {
        if (user) {
            dispatch('bindParty');
            dispatch('bindLoot');
            dispatch('bindMacros');
            dispatch('bindLogs');
        }
        else {
            dispatch('logOut')
        }
    });
}

/****************
 * AUTH ACTIONS *
 ****************/

// log the party in
const logIn = async ({ dispatch }, loginDetails) => {
    return new Promise((resolve, reject) => {
        // attempt to sign in
        // if sign in fails, return the error to the component
        // otherwise load all listeners and resolve the component
        const auth = getAuth();
        signInWithEmailAndPassword(auth, loginDetails.email, loginDetails.partySecret)
        .then(() => {
            dispatch('loadListeners')
            resolve();
        })
        .catch(() => {
            reject('Invalid party name or secret.');
        })
    });
}

// log the party out
const logOut = ({ state, commit }) => {
    try {
        // unsubscribe from each listener
        state.unsub.forEach((f) => {
            f();
        })
    }
    catch { 
        // do nothing
    }
    finally {  
        // log the party out
        commit('logOut')
    }
}
  
// create a new party
const createParty = async ( { dispatch }, partyDetails) => {
    return new Promise((resolve, reject) => {
        // check if the party details are valid
        // if they are not, return the error to the component
        if (validatePartyDetails(partyDetails)) {
            // attempt to create party account
            // if account creation fails, return the error to the component
            const auth = getAuth();
            createUserWithEmailAndPassword(auth, partyDetails.partyEmail, partyDetails.partySecret)
            .then(async () => {
                // create a document for the new party
                const docData = {
                    party_email: partyDetails.partyEmail,
                    party_name: partyDetails.partyName,
                    party_members: [],
                    coins: { 
                        'platinum' : 0, 
                        'gold' : 0, 
                        'electrum' : 0, 
                        'silver' : 0, 
                        'copper' : 0 
                    },
                    notes: ""
                };

                // push the party document, using the user's ID as the party's ID
                // if the party details are invalid, return the error to the component
                await setDoc(doc(db, "parties", auth.currentUser.uid), docData)
                .catch(() => {
                    reject('Invalid party details.')
                });

                // create a login object   
                const loginDetails = {
                    'email' : partyDetails.partyEmail,
                    'partySecret' : partyDetails.partySecret
                };

                // log the new user in
                // if the login fails, return the error to the component
                dispatch('logIn', loginDetails)
                .then(() => {      
                    // resolve the component
                    resolve();
                })
                .catch(() => {
                    reject('Invalid party details.')
                });   
            })
            .catch(() => {
                reject('Party name already exists.');
            })
        } else {
            reject('Invalid party details.');
        }
    });
}

/****************
 * LOOT ACTIONS *
 ****************/

// add a loot item
const addLoot = async ({ dispatch, state }, item) => {
    return new Promise((resolve, reject) => {
        // check auth before performing action
        // log out if the user is not authenticated
        getAuth().onAuthStateChanged(async (user) => {
            if (user) {
                // check if loot item is valid
                if (validateItem(item, state.party.partyMembers)) {
                    // create a document for the loot item
                    const docData = {
                        name: item.name,
                        description: item.description,
                        value_amount: item.valueAmount,
                        value_currency: item.valueCurrency,
                        weight: item.weight,
                        individuals: item.individuals,
                        tags: item.tags,
                        created_at : Timestamp.fromMillis(item.createdAt),
                        last_modified_at : Timestamp.fromMillis(item.lastModifiedAt)
                    }

                    // add the item document
                    // if the item details are invalid, return the error to the component
                    const partyRef = doc(db, "parties", state.party.id);
                    await addDoc(collection(partyRef, "items"), docData)
                    .catch(() => {
                        reject('Invalid loot item details.')
                    });

                    // resolve the component
                    resolve();
                } else {
                    reject('Invalid party details.');
                }
            }
            else {
                dispatch('logOut')
            }
        });
    });
}

// edit a loot item
const editLoot = async ({ dispatch, state }, item) => {
    return new Promise((resolve, reject) => {
        // check auth before performing action
        // log out if the user is not authenticated
        getAuth().onAuthStateChanged(async (user) => {
            if (user) {
                // check if loot item is valid
                if (validateItem(item, state.party.partyMembers)) {
                    // create a document for the updated loot item
                    const docData = {
                        name: item.name,
                        description: item.description,
                        value_amount: item.valueAmount,
                        value_currency: item.valueCurrency,
                        weight: item.weight,
                        individuals: item.individuals,
                        tags: item.tags,
                        created_at : Timestamp.fromMillis(item.createdAt),
                        last_modified_at : Timestamp.fromMillis(item.lastModifiedAt)
                    }

                    // edit the item document
                    // if the item details are invalid, return the error to the component
                    const partyRef = doc(db, "parties", state.party.id);
                    await setDoc(doc(partyRef, "items", item.id), docData)
                    .catch(() => {
                        reject('Invalid loot item details.')
                    });

                    // resolve the component
                    resolve();
                } else {
                    reject('Invalid party details.');
                }
            }
            else {
                dispatch('logOut');
            }
        });
    });
}

// delete a loot item
const deleteLoot = async({ dispatch, state }, itemId) => {
    return new Promise((resolve, reject) => {
        // check auth before performing action
        // log out if the user is not authenticated
        getAuth().onAuthStateChanged(async (user) => {
            if (user) {
                // delete the loot item
                // if the item id is invalid, return the error to the component
                const partyRef = doc(db, "parties", state.party.id);
                await deleteDoc(doc(partyRef, "items", itemId))
                .catch(() => {
                    reject('Invalid loot id.')
                });

                // resolve the component
                resolve();
            }
            else {
                dispatch('logOut')
            }
        });
    });
}

/*****************
 * MACRO ACTIONS *
 *****************/

// add a macro
const addMacro = async ({ dispatch, state }, macro) => {
    return new Promise((resolve, reject) => {
        // check auth before performing action
        // log out if the user is not authenticated
        getAuth().onAuthStateChanged(async (user) => {
            if (user) {
                // check if macro is valid
                if (validateMacro(macro)) {
                    // create a document for the macro
                    const docData = {
                        name: macro.name,
                        description: macro.description,
                        value_amount: macro.valueAmount,
                        value_currency: macro.valueCurrency,
                        transaction_type: macro.transactionType,
                    }

                    // add the macro document
                    // if the macro details are invalid, return the error to the component
                    const partyRef = doc(db, "parties", state.party.id);
                    await addDoc(collection(partyRef, "macros"), docData)
                    .catch(() => {
                        reject('Invalid macro details.')
                    });
                }

                // resolve the component
                resolve();
            }
            else {
                dispatch('logOut')
            }
        });
    });
}

// edit a macro
const editMacro = async ({ dispatch, state }, macro) => {
    return new Promise((resolve, reject) => {
        // check auth before performing action
        // log out if the user is not authenticated
        getAuth().onAuthStateChanged(async (user) => {
            if (user) {
                // check if macro is valid
                if (validateMacro(macro)) {
                    // create a document for the updated macro
                    const docData = {
                        name: macro.name,
                        description: macro.description,
                        value_amount: macro.valueAmount,
                        value_currency: macro.valueCurrency,
                        transaction_type: macro.transactionType,
                    }

                    // edit the macro document
                    // if the macro details are invalid, return the error to the component
                    const partyRef = doc(db, "parties", state.party.id);
                    await setDoc(doc(partyRef, "macros", macro.id), docData)
                    .catch(() => {
                        reject('Invalid macro details.')
                    });

                    // resolve the component
                    resolve();
                }
            }
            else {
                dispatch('logOut')
            }
        });
    });
}

// delete a macro
const deleteMacro = async ({ dispatch, state }, macroId) => {
    return new Promise((resolve, reject) => {
        // check auth before performing action
        // log out if the user is not authenticated
        getAuth().onAuthStateChanged(async (user) => {
            if (user) {
                // delete the macro
                // if the macro id is invalid, return the error to the component
                const partyRef = doc(db, "parties", state.party.id);
                await deleteDoc(doc(partyRef, "macros", macroId))
                .catch(() => {
                    reject('Invalid macro id.')
                });

                // resolve the component
                resolve()
            }
            else {
                dispatch('logOut')
            }
        });
    });
}

/***********************
 * TRANSACTION ACTIONS *
 ***********************/

const addTransaction = async ({ dispatch, state }, transaction) => {
    return new Promise((resolve, reject) => {
        // check auth before performing action
        // log out if the user is not authenticated
        getAuth().onAuthStateChanged(async (user) => {
            if (user) {
                // if the transaction is valid
                if (validateTransaction(transaction)) {
                    // ensure that all coin quantity values are ints
                    for (var currency in transaction.coins) {
                        transaction.coins[currency] = parseInt(transaction.coins[currency])
                    }

                    // update party coins
                    // if the transaction details are invalid, return the error to the component
                    const partyRef = doc(db, "parties", state.party.id);
                    await updateDoc(partyRef, { coins: transaction.coins })
                    .catch(() => {
                        reject('Invalid transaction deatils.')
                    });

                    // if this is a proper transaction
                    // (rather than a direct edit of the coins)
                    if (transaction.transactionType != 'edit') {
                        // create a document to log the transaction
                        const logData = {
                            time : Timestamp.now(),
                            reason: transaction.reason,
                            transaction_type: transaction.transactionType,
                            value_amount: transaction.valueAmount,
                            value_currency: transaction.valueCurrency
                        }

                        // add the log document
                        // if the log details are invalid, return the error to the component
                        await addDoc(collection(partyRef, "logs"), logData)
                        .catch(() => {
                            reject('Invalid transaction details.')
                        });

                        // if there are more than 50 logs, find the oldest one and delete it
                        if (state.logs.length > 50) {
                            let oldestLog = state.logs[0];
                            state.logs.forEach((log) => {
                                if (new Date(log.time) < new Date(oldestLog.time)) {
                                    oldestLog = log;
                                }
                            })

                            await deleteDoc(doc(partyRef, "logs", oldestLog.id))
                            .catch(() => {
                                reject('Invalid transaction details.')
                            });
                        }
                    }

                    // resolve the component
                    resolve()
                }
            }
            else {
                dispatch('logOut')
            }
        });
    });
}

const reverseTransaction = async ({ dispatch, state }, transaction) => {
    return new Promise((resolve, reject) => {
        // check auth before performing action
        // log out if the user is not authenticated
        getAuth().onAuthStateChanged(async (user) => {
            if (user) {
                // if the transaction is valid
                if (validateTransaction(transaction)) {
                    // ensure that all coin quantity values are ints
                    for (var currency in transaction.coins) {
                        transaction.coins[currency] = parseInt(transaction.coins[currency])
                    }

                    // update party coins
                    // if the transaction details are invalid, return the error to the component
                    const partyRef = doc(db, "parties", state.party.id);
                    await updateDoc(partyRef, { coins: transaction.coins })
                    .catch(() => {
                        reject('Invalid transaction details.')
                    });

                    // delete the old transaction
                    await deleteDoc(doc(partyRef, "logs", transaction.id))
                    .catch(() => {
                        reject('Invalid transaction details.')
                    });

                    // resolve the component
                    resolve()
                }
            }
            else {
                dispatch('logOut')
            }
        });
    });
}

/*****************
 * NOTES ACTIONS *
 *****************/

// edit notes
const editPartyNotes = async ({ dispatch, state }, notes) => {
    return new Promise((resolve, reject) => {
        // check auth before performing action
        // log out if the user is not authenticated
        getAuth().onAuthStateChanged(async (user) => {
            if (user) {
                // check if notes are valid
                if (validatePartyNotes(notes)) {
                    // update party notes
                    // if the notes details are invalid, return the error to the component
                    const partyRef = doc(db, "parties", state.party.id);
                    await updateDoc(partyRef, { notes: notes.notes })
                    .catch(() => {
                        reject('Invalid notes details.')
                    });

                    // resolve the component
                    resolve();
                }
            }
            else {
                dispatch('logOut')
            }
        });
    });
}

/********************
 * SETTINGS ACTIONS *
 ********************/

// edit party members
const editPartyMembers = async ({ dispatch, state }, partyMembersDetails) => {
    return new Promise((resolve, reject) => {
        // check auth before performing action
        // log out if the user is not authenticated
        getAuth().onAuthStateChanged(async (user) => {
            if (user) {
                // if a party member is being added or edited, check that their name is valid
                // otherwise the party member is being deleted
                let isValid = true;
                if (partyMembersDetails.operation != 'delete') {
                    isValid = validatePartyMembers(partyMembersDetails.newMember, state.party.partyMembers)
                }

                // if the operation is valid
                if (isValid) {
                    // update party members
                    // if the party member details are invalid, return the error to the component
                    const partyRef = doc(db, "parties", state.party.id);
                    await updateDoc(partyRef, {  party_members: partyMembersDetails.partyMembers })
                    .catch(() => {
                        reject('Invalid party member deatils.')
                    });

                    // resolve the component
                    resolve()
                } else {
                    reject('Invalid party member deatils.')
                }
            }
            else {
                dispatch('logOut')
            }
        });
    });
}

const updatePartyDetails = async ({ dispatch, state }, partyDetails) => {
    return new Promise((resolve, reject) => {
        // check auth before performing action
        // log out if the user is not authenticated
        const auth = getAuth();
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                // check if party details are valid
                if (validatePartyDetails(partyDetails)) {
                    // attempt to reauthenticate with credentials to be sure the transaction succeeds
                    // if reauthentication fails, return an error message to the component
                    const credential = EmailAuthProvider.credential(partyDetails.partyEmail, partyDetails.partySecret);
                    reauthenticateWithCredential(auth.currentUser, credential)
                    .then(async () => {
                        // update party email
                        // if the party details are invalid, return the error to the component
                        if (partyDetails.partyEmailNew != partyDetails.partyEmail) {
                            updateEmail(auth.currentUser, partyDetails.partyEmailNew)
                            .then(() => { })
                            .catch(() => {
                                reject('Invalid party details')
                            })
                        }

                        // update party name
                        // if the party details are invalid, return the error to the component
                        const partyRef = doc(db, "parties", state.party.id);
                        if (partyDetails.partyName != state.party.partyName) {
                            await updateDoc(partyRef, { 
                                party_name: partyDetails.partyName 
                            })
                            .catch(() => {
                                reject('Invalid party details.')
                            });        
                        }

                        // if the password has been updated, change it
                        // if the party details are invalid, return the error to the component
                        if (partyDetails.partySecretNew != '') {
                            updatePassword(auth.currentUser, partyDetails.partySecretNew)
                            .then(() => { })
                            .catch(() => {
                                reject('Invalid party details.')
                            })
                        }

                        // resolve component
                        resolve();
                    })
                    .catch(() => {
                        reject('Invalid party secret')
                    })
                } else {
                    reject();
                }
            }
            else {
                dispatch('logOut');
                reject();
            }
        })
    })
}

// delete account
const deleteAccount = async ({ dispatch, state }) => {
    // check auth before performing action
    // log out if the user is not authenticated
    const auth = getAuth();
    auth.onAuthStateChanged(async (user) => {
        if (user) {
            // fetch the party details
            const partyRef = doc(db, "parties", state.party.id);
            const partyDoc = await getDoc(partyRef);

            // if the party document exists, delete all subcollections one item at a time
            // then delete the party document
            if (partyDoc.exists()) {
                const lootDocs = await getDocs(collection(partyRef, "items"));
                const macrosDocs = await getDocs(collection(partyRef, "macros"));
                const logsDocs = await getDocs(collection(partyRef, "logs"));

                lootDocs.forEach(async (item) => {
                    await deleteDoc(doc(partyRef, "items", item.id))
                })

                macrosDocs.forEach(async (item) => {
                    await deleteDoc(doc(partyRef, "macros", item.id))
                })

                logsDocs.forEach(async (item) => {
                    await deleteDoc(doc(partyRef, "logs", item.id))
                })

                await deleteDoc(partyRef);
            }

            // delete the user from Firebase auth
            deleteUser(auth.currentUser);

            // log out for the last time
            dispatch('logOut')
        }
    });
}

/************************
 * VALIDATION FUNCTIONS *
 ************************/

// validate a loot item
function validateItem(item, partyMembers) {
    let valid = true;
    
    // name must be between 1 and 100 characters
    if (item.name.length > 100) {
        valid = false;
    }

    // description must be less than 9999 characters
    if (item.description.length > 9999) {
        valid = false;
    }

    // weight must be a number
    if (isNaN(parseFloat(item.weight))) {
        valid = false;
    }
    
    // amount must be a number
    if (isNaN(parseInt(item.valueAmount))) {
        valid = false;
    }
    
    // currency must be valid
    if (item.valueCurrency != 'platinum' && item.valueCurrency != 'gold' 
        && item.valueCurrency != 'electrum' && item.valueCurrency != 'silver' 
        && item.valueCurrency != 'copper' && item.valueCurrency != '') {
        valid = false;
    }
    
    // tags must be less than 100 characters each
    item.tags.forEach((tag) => {
        if (tag.length > 100) {
            valid = false;
        }
    })
    
    // individual quantity must be a number
    // individual owners must be party members
    item.individuals.forEach((i) => {
        if (isNaN(parseInt(i.quantity))) {
            valid = false;
        }
        if (!partyMembers.includes(i.owner) && i.owner != 'Party') {
            valid = false;
        }
    })
    
    return valid;
}

// validate a macro
function validateMacro(macro) {
    let valid = true;

    // name must be between 1 and 100 characters
    if (macro.name.length > 100) {
        valid = false;
    }

    // description must be less than 9999 characters
    if (macro.description.length > 9999) {
        valid = false;
    }

    // amount must be a number
    if (isNaN(parseInt(macro.valueAmount))) {
        valid = false;
    }

    // currency must be valid
    if (macro.valueCurrency != 'platinum' && macro.valueCurrency != 'gold' 
        && macro.valueCurrency != 'electrum' && macro.valueCurrency != 'silver' 
        && macro.valueCurrency != 'copper') {
        valid = false;
    }

    // transaction type must be valid
    if (macro.transactionType != 'income' && macro.transactionType != 'expense' && macro.transactionType != 'edit') {
        valid = false;
    }

    return valid;
}

// validate a transaction
function validateTransaction(transaction) {
    let valid = true;

    // reason must be less than 9999 characters
    if (transaction.reason.length > 9999) {
        valid = false;
    }

    // amount must be a number
    if (isNaN(parseInt(transaction.valueAmount))) {
        valid = false;
    }

    // currency must be valid
    if (transaction.valueCurrency != 'platinum' && transaction.valueCurrency != 'gold' 
        && transaction.valueCurrency != 'electrum' && transaction.valueCurrency != 'silver' 
        && transaction.valueCurrency != 'copper' && transaction.transactionType != 'edit') {
        valid = false;
    }

    // transaction type must be valid
    if (transaction.transactionType != 'income' && transaction.transactionType != 'expense' && transaction.transactionType != 'edit') {
        valid = false;
    }

    return valid;
}

// validate notes
function validatePartyNotes(notes) {
    let valid = true;

    if (notes.length > 10000) {
        valid = false
    }

    return valid;
}

// validate a party member
function validatePartyMembers(partyMember, partyMembers) {
    let valid = true;

    // party member name must be between 1 and 100 characters
    if (partyMember.length === 0 || partyMember.length > 100) {
        valid = false;
    }

    // party member cannot already be in party
    if (partyMembers.includes(partyMember)) {
        valid = false;
    }

    return valid;
}

// validate party details
function validatePartyDetails(partyDetails) {
    let valid = true;

    // party name must be between 1 and 100 characters
    if (partyDetails.partyName.length === 0 || partyDetails.partyName.length > 100) {
        valid = false;
    }

    // party secret must be between 5 and 100 characters
    if (partyDetails.partySecret != '' && (partyDetails.partySecret.length < 5 || partyDetails.partySecret.length > 100)) {
        valid = false;
    }

    return valid;
}

/******************
 * EXPORT ACTIONS *
 ******************/

// export all actions
export default {
    bindParty,
    bindLoot,
    bindMacros,
    bindLogs,
    logIn,
    logOut,
    loadListeners,
    createParty,
    addLoot,
    editLoot,
    deleteLoot,
    addMacro,
    editMacro,
    deleteMacro,
    addTransaction,
    reverseTransaction,
    editPartyNotes,
    editPartyMembers,
    updatePartyDetails,
    deleteAccount
};