/************
 *  GETTERS *
 ************/

// check if the user is logged in
const loggedIn = (state) => {
    return (state.party != null)
}

// fetch party id
const partyId = (state) => {
    return state.party.id;
}

// fetch party name
const partyName = (state) => {
    return state.party.partyName;
}

// fetch party members
const partyMembers = (state) => {
    return state.party.partyMembers;
}

// fetch party notes
const partyNotes = (state) => {
    return state.party.notes;
}

// fetch party coins
const partyCoins = (state) => {
    return state.party.coins;
}

// fetch party loot
const partyLoot = (state) => {
    return state.loot;
}

// fetch tags belonging to all party loot
const partyLootTags = (state) => {
    const tags = new Set();
    state.loot.forEach((item) => {
        item.tags.forEach((tag) => { 
            tags.add(tag); 
        });
    });

    return Array.from(tags);
}

// fetch party macros
const partyMacros = (state) => {
    return state.macros;
}

// fetch party logs
const partyLogs = (state) => {
    return state.logs;
}

/******************
 * EXPORT GETTERS *
 ******************/

// export all getters
export default {
    loggedIn,
    partyId,
    partyName,
    partyMembers,
    partyNotes,
    partyCoins,
    partyLoot,
    partyLootTags,
    partyMacros,
    partyLogs,
};