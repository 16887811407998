import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage, // allows state to persist in session storage
  })],
  state: {
      party: null,  // party
      loot: [],     // party loot
      logs: [],     // party logs
      macros: [],   // party macros
      unsub: [],    // unsub functions for firestore
  },
  mutations,
  actions,
  getters
})
