import { extend, setInteractionMode } from 'vee-validate';
import { required, min, max, double, numeric } from 'vee-validate/dist/rules'

setInteractionMode('eager')

extend('required', {
    ...required,
    message: '{_field_} cannot be empty.',
})

extend('min', {
    ...min,
    message: '{_field_} must have at least {length} characters.',
});

extend('max', {
    ...max,
    message: '{_field_} may not exceed {length} characters.',
})

extend('double', {
    ...double,
    message: '{_field_} must be an integer or decimal value.',
})

extend('numeric', {
    ...numeric,
    message: '{_field_} must be an integer value.',
})