<template>
  <v-app :style="{ background: $vuetify.theme.themes['light'].background }">
    <v-navigation-drawer 
      v-model="drawer"
      absolute
      temporary
    >
      <v-list-item 
        v-on:click="$router.push({ name: 'HomeView' }).catch(()=>{})"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <span class="mr-2">Home</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
       <v-list-item 
        v-on:click="$router.push({ name: 'NotesView' }).catch(()=>{})"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-file</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <span class="mr-2">Notes</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item 
        v-on:click="$router.push({ name: 'SettingsView' }).catch(()=>{})"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <span class="mr-2">Settings</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
       <v-list-item 
        v-on:click="$router.push({ name: 'HelpView' }).catch(()=>{})"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-help-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <span class="mr-2">Help</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item 
        v-on:click="drawer=false; $store.dispatch('logOut'); $router.push({ name: 'HomeView' }).catch(()=>{})"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <span class="mr-2">LogOut</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>
    <v-btn 
      v-on:click="drawer=!drawer"
      v-if="loggedIn"
      aria-label="navigation"
      x-large 
      icon
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
      return {
        drawer: false
      }
  },
  computed: {
    // check whether the user is logged in
    loggedIn: function() {
      return this.$store.getters.loggedIn;
    }
  },
  mounted() {
    // if the user is logged in, load all listeners
    if(this.loggedIn) {
      this.$store.dispatch('loadListeners');
    }
  }
};
</script>
